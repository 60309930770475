<template>
  <b-overlay :show="GET_FETCH_START_UP_RECORD" blur="5px" >
    <div class="dashboard">
      <Common />
      <Sidebar />
      <Content />
    </div>
  </b-overlay>
</template>

<script>
import Content from '@/components/dashboard/Content.vue'
import Sidebar from '@/components/dashboard/Sidebar.vue'
import Common from '@/components/common/index.vue'
import { mapGetters } from 'vuex'
import { GET_FETCH_START_UP_RECORD } from '@/store/constants'
export default {
  name: 'DashboardLayout',
  components: {
    Content,
    Sidebar,
    Common,
  },
  computed: {
    ...mapGetters([
      GET_FETCH_START_UP_RECORD
    ])
  },
}
</script>

<style lang="scss" scoped>

.dashboard {
  display: grid;
  grid-template-columns: 300px calc(100% - 300px);
  // grid-template-rows: 65px calc(100% - 65px);
  grid-template-areas: 
    'sideBar            content'
  ;
  height: 100%;
  width: 100%;
  overflow: hidden;
  // background-color: $light-card;
  > .content {
    margin-left: 0px;
  }
  &:has(.md-sideBar.SubSideBarIsActive){
    grid-template-columns: 500px calc(100% - 500px);
  }
}
</style>