import { routes_names } from "@/router/constants";

export const sidebar = {
  templates: {
    text: 'Templates',
    children: {
      template_library: {
        text: 'Template library',
        icon: 'blocks',
        to: {
          name: routes_names.templates,
        },
      },
      community: {
        text: 'Community',
        icon: 'Community',
        to: {
          name: routes_names.community,
        },
      },
    }
  },
  settings: {
    text: 'Settings',
    children: {
      account: {
        text: 'Account',
        icon: 'gear',
        to: {
          name: routes_names.account,
        },
      },
      preferences: {
        text: 'Preferences',
        icon: 'preference',
        to: {
          name: routes_names.preferences,
        },
      },
      memory: {
        text: 'Memory',
        icon: 'memory',
        to: {
          name: routes_names.memory,
        },
      },
      // team: {
      //   text: 'Team',
      //   icon: 'building',
      //   to: {
      //     name: routes_names.team,
      //   },
      // },
      // integrations: {
      //   text: 'Integrations',
      //   icon: 'plug-zap',
      //   to: {
      //     name: routes_names.integrations,
      //   },
      // },
    }
  },
}