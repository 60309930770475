<template>
  <div ref="side-bar" class="md-sideBar">
    <div class="md-sideBar-inner">
      <div class="sideBar-userInfo">
        <div class="sideBar-userInfo-inner">
          <b-dropdown v-if="GET_USER" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <div class="sideBar-userInfo-leftSide">
                <div class="userInfo-initials" @click.stop="$refs['side-bar'].classList.remove('minimizedVersion')">
                  <div class="userInfo-initials-text">
                    <!-- {{ GET_USER.first_name | firstCharacter }}{{ GET_USER.last_name | firstCharacter }} -->
                    <app-icon icon="userDefaultProfile" />
                  </div>
                </div>
                <div class="userInfo-nameEmail">
                  <p class="userInfo-name mb-0">{{GET_USER.first_name}} {{ GET_USER.last_name }}</p>
                  <p v-if="!GET_USER.defaultSetting.hide_email_sidebar" class="userInfo-email mb-0">{{ GET_USER | get_property('emails[0].email') }}</p>
                </div>
              </div>
            </template>
            <b-dropdown-item @click="LOGOUT()">Logout</b-dropdown-item>
          </b-dropdown>
          <b-button variant="link" @click="$refs['side-bar'].classList.add('minimizedVersion')">
            <!-- <app-icon icon="collapse" data-state="closed" /> -->
            <b-icon icon="chevron-right" variant="white" />
          </b-button>
        </div>
      </div>
      <div class="sideBar-devider"></div>
      <div class="sideBar-scrollSection">
        <div class="sideBar-scrollSection-topSection">
          <button class="smallFullWidthBlackButton" @click="createSession()">
            <b-icon icon="plus-circle" />
            <span>New session</span>
          </button>
          <ul class="sideBar-list">
            <li class="sideBar-list-item justify-content-between" @click="$refs['side-bar'].classList.toggle('SubSideBarIsActive')">
              <div class="d-flex justify-content-between align-items-center">
                <app-icon icon="person-search" class="sessionIcon" style="height: 14.93px;width: 13.27px;" />
                <span class="ml-2">View sessions</span>
              </div>
              <span class="sideBar-list-item-text d-flex justify-content-between align-items-center">
                <app-icon icon="chevron-right" />
              </span>
            </li>
          </ul>
          <template v-for="(data, key) in sidebar">
            <div :key="key" class="sideBar-list-label">
              <span>{{data.text}}</span>
            </div>
            <ul :key="`${key}-children`" class="sideBar-list">
              <router-link v-for="(data1, key1) in data.children" :key="key1" class="sideBar-list-item" exact-active-class="active" :to="data1.to">
                <app-icon :icon="data1.icon" />
                <span class="sideBar-list-item-text">{{data1.text}}</span>
              </router-link>
            </ul>
          </template>
        </div>
        <!-- <div class="sideBar-scrollSection-bottomSection">
          <div class="sideBar-devider"></div>
          <ul class="sideBar-list">
            <li class="sideBar-list-item">
              <app-icon icon="badge-dollar-sign" />
              <span>Earn $50</span>
            </li>
            <li class="sideBar-list-item">
              <app-icon icon="map" />
              <span>Roadmap</span>
            </li>
            <li class="sideBar-list-item justify-content-between">
              <div class="d-flex justify-content-between align-items-center">
                <app-icon icon="command" />
                <span class="ml-2">Shortcuts</span>
              </div>
              <span>S</span>
            </li>
          </ul>
          <button class="smallFullWidthGreyButton">
            <app-icon icon="circle-help" />
            <span>Help</span>
          </button>
        </div> -->
      </div>
    </div>
    <div class="md-sideBar-inner sessionsSectionPart">
      <div class="SubSideBar-heading">Sessions</div>
      <div class="whiteBGInput-withIcon-container mt-3 w-100">
        <app-icon icon="search" />
        <input v-model="filter.sessions.search" placeholder="Search" style="width: calc(100% - 8px - 16px);min-width: auto" />
      </div>
      <!-- <div class="minimaltabsHeader">
        <div class="tabsHeader-item w-50" :class="{'active': selected.tab=='schedule'}" @click="selected.tab='schedule'">
          <div class="tabsHeader-item-text">Schedule</div>
        </div>
        <div class="tabsHeader-item w-50" :class="{'active': selected.tab=='past'}" @click="selected.tab='past'">
          <div class="tabsHeader-item-text">Past</div>
        </div>
      </div> -->
      <div class="slidingCheckbox-container" style="height: calc(100% - 140px);">
        <div v-if="selected.tab=='past'" class="d-flex flex-column overflow-auto justify-content-between h-100">
          <div class="d-flex flex-column">
            <b-checkbox :disabled="api.delete_sessions.send" v-for="session in sessions" :key="session.id" :checked="!!selected.sessions[session.id]" @change="selected.sessions[session.id] ? $delete(selected.sessions,session.id) : $set(selected.sessions,session.id,session.id)">
              <router-link tag="div" :to="{ name: routesNames.session, params: { session_id: session.id }}" exact>
                <div class="slidingCheckbox-bigText">{{session.patient_name || session.name}}</div>
                <div class="slidingCheckbox-smallText">{{session.created_at | date_format}}</div>
              </router-link>
            </b-checkbox>
          </div>
          <div class="d-flex flex-column">
            <b-button :disabled="api.delete_sessions.send" class="blackButtonWithIcon w-100" v-if="selectedSessions.length" @click="deleteSessions()">
              <app-icon icon="trash" />
              Delete Selected ({{selectedSessions.length}})
            </b-button>
          </div>
        </div>
        <div v-if="selected.tab=='schedule'">
          Progress
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ADD_SESSION, FETCH_SESSIONS, GET_IS_MAIN_USER, GET_SESSIONS, GET_USER, LOGOUT } from '@/store/constants'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { sidebar } from '@/constants'
import { routes_names } from '@/router/constants'
import { API } from '@/services'
export default {
  name: 'Sidebar',
  data() {
    return {
      api: {
        create_session: this.$appInstance.api({  }),
        delete_sessions: this.$appInstance.api({  }),
      },
      selected: {
        tab: 'past',
        sessions: {},
      },
      filter: {
        sessions: {
          search: '',
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_USER,
      GET_IS_MAIN_USER,
      GET_SESSIONS,
    ]),
    routesNames(){ return routes_names },
    sidebar(){ return sidebar },
    sessions(){ return Object.values(this.GET_SESSIONS).filter(i=>i.patient_name ? i.patient_name.includes(this.filter.sessions.search) : i.name.includes(this.filter.sessions.search)) },
    selectedSessions(){ return Object.keys(this.selected.sessions) },
  },
  filters: {
    firstCharacter(value) { return value?.at?.(0)?.toUpperCase?.() || '' }
  },
  methods: {
    ...mapActions([
      LOGOUT,
      FETCH_SESSIONS
    ]),
    ...mapMutations([
      ADD_SESSION,
    ]),
    async createSession(){
      if(this.api.create_session.send) return;
      try {
        this.api.create_session.send=true
        const { data: session } = await API.endpoints.sessions.create()
        this.ADD_SESSION(session)
        this.$refs['side-bar'].classList.add('SubSideBarIsActive')
        this.$router.push({ 
          name: routes_names.session, 
          params: { 
            session_id: session.id 
          } 
        })
      } catch (ex) {
        this.$appToaster({
          message: ex.own_message || ex.message, 
          type: 'error',
        })
      } finally {
        this.api.create_session.send=false
      }
    },
    async deleteSessions(){
      if(this.api.delete_sessions.send) return;
      try {
        this.api.delete_sessions.send=true
        await API.endpoints.sessions.delete({
          session_ids: Object.keys(this.selected.sessions)
        })
        this.selected.sessions={}
        this.FETCH_SESSIONS()
      } catch (ex) {
        this.$appToaster({
          message: ex.own_message || ex.message, 
          type: 'error',
        })
      } finally {
        this.api.delete_sessions.send=false
      }
    }
  },
}
</script>

<style lang="scss">
@import '~/node_modules/bootstrap/scss/_functions.scss';
@import '~/node_modules/bootstrap/scss/_variables.scss';
.sidebar {
  grid-area: sideBar;
  color: $black;
  padding-left: 15px;
  > ul {
    list-style: none;
    padding: 0px;
    background: linear-gradient(to right,  $light-card 0%, $light-card 50%, $white 50%, $white 100%);
    > li {
      &:not(.router-link-exact-active):hover {
        padding: 2px;
        padding-right: 15px;
        > div {
          background-color: #ffffffaa;
          padding: 8px;
          border-radius: 50px;
        }
      }
      background-color: $light-card;
      padding: 10px;
      &.for-border {
        height: 20px;
      }
      &:has(+ .router-link-exact-active) {
        border-bottom-right-radius: 20px;
      }
      &.router-link-exact-active + li {
        border-top-right-radius: 20px;
      }
      &.router-link-exact-active {
        background-color: $white;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }
    }
  }
}
.md-sideBar{
  height: 100vh;
  width: 300px;
  grid-area: sideBar;
  .md-sideBar-inner{
    padding-top: 1rem;
    padding-bottom: .5rem;
    padding-left: 32px;
    padding-right: 32px;
    border-right-width: 1px;
    border-right: 1px solid rgb(226, 232, 240);
    // background-color: white;
    background-color: #101010;

    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 300px;
    height: 100%;
  }
  .sideBar-scrollSection{
    width: -webkit-fill-available;
    overflow-y: auto;
    justify-content: space-between;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    display: flex;
    .sideBar-scrollSection-topSection{
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .sideBar-scrollSection-bottomSection{
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 16px;
    }
  }
  &.SubSideBarIsActive{
    display: flex;
    width: 500px;
    .md-sideBar-inner{
      width: 50%;
    }
  }
}
.sideBar-userInfo{
  width: 100%;
  .sideBar-userInfo-inner{
    width: 100%;
    // padding-left: .5rem;
    // padding-right: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    > .dropdown {
      width: calc(100% - 20px);
      > .dropdown-toggle {
        width: inherit;
      }
    }
    .sideBar-userInfo-leftSide{
      gap: .5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // max-width: calc(100% - 18px - 8px);
      .userInfo-initials{
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;

        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        .userInfo-initials-text{
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .userInfo-nameEmail{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100% - 44px - 8px);
        text-align: right;
        .userInfo-name{
          font-size: 16px;
          font-weight: 700;
          line-height: 1.3;
          // color: rgb(2, 8, 23);
          color:white;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          text-align: right;
        }
        .userInfo-email{
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          // color: rgb(100 116 139);
          color:#8F8F8F;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
    svg{
      cursor: pointer;
    }
  }
}
.sideBar-devider{
  height: 1px;
  background-color: rgb(226, 232, 240);
}
.sideBar-list-label{
  font-size: 10px;
  line-height: 1.3;
  font-weight: 500;
  // color: rgb(100, 116, 139);
  color:white;
  padding: 0px 6px 4px 6px;
}
.sideBar-list{
  width: 100%;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  .sideBar-list-item{
    width: 100%;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 500;
    padding: 12px;
    white-space: nowrap;
    column-gap: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    // color: rgb(100, 116, 139);
    color:white;
    margin-bottom: 1px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    &:hover , &.active{
      // background-color: rgb(241, 245, 249);
      // color: rgb(15, 23, 42);
      background-color: #2D2D2D;
      color:white;
    }
    // .sideBar-list-item-text{

    // }
    svg{
      height: 22px;
      width: 22px;
    }
  }
}

.SubSideBar-heading{
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  // color: rgb(2, 8, 23);
  color:white;
}
.slidingCheckbox-container{
  .slidingCheckbox-bigText{
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    // color: rgb(2, 8, 23);
    color:white;
  }
  .slidingCheckbox-smallText{
    font-size: 10px;
    font-weight: 400;
    line-height: 1.3;
    // color: rgb(100, 116, 139);
    color:white;
  }
  .custom-control.custom-checkbox{
    padding: 5px 34px 5px 12px;
    
		-webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    transition: all 0.25s ease-in-out;
    .custom-control-label{
      width: 100%;
      &::before{
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: -22px;
        left: unset;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        border: 2px solid #323232;
        background-color: transparent;
        height: 18px;
        width: 18px;
      }
      &::after{
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: -22px;
        left: unset;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        opacity: 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg id='check-circle-svgrepo-com' transform='translate(-2 -2)'%3E%3Cpath id='Path_122271' data-name='Path 122271' d='M21,12a9,9,0,1,1-9-9A9,9,0,0,1,21,12Z' fill='none' stroke='rgba(50,50,50,0)' stroke-width='2'/%3E%3Cpath id='Path_122272' data-name='Path 122272' d='M9,12l1.683,1.683h0a.449.449,0,0,0,.634,0h0L15,10' fill='none' stroke='%23323232' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
        background-size: 18px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after{
      opacity: 1 !important;
    }
    // .custom-control-label{
    //   &::before{
    //     opacity: 0;
    //     transition: all .1s ease-in-out;
    //     transition-delay: 0.1s;
    //   }
    //   &::after{
    //     opacity: 0;
    //     transition: all .1s ease-in-out;
    //     transition-delay: 0.1s;
    //   }
    // }
    // &:hover{
    //   background-color: rgb(241, 245, 249);
    //   padding-left: 32px;
    //   .custom-control-label{
    //     &::before{
    //       opacity: 1;
    //       transition: all .1s ease-in-out;
    //       transition-delay: 0.1s;
    //     }
    //     &::after{
    //       opacity: 1;
    //       transition: all .1s ease-in-out;
    //       transition-delay: 0.1s;
    //     }
    //   }
    // }
  }
}
</style>